import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
  negotiationList: [],
  renegotiationList: [],
  oldNegotiationList: [],
  oldRenegotiationList: [],
  oldPrepaymentList: [],
  prepaymentList: [],
  loading: false
};

const initPricingApproveList = (state, action) => {
  return updateObject(state, {
    ...state,
    loading: true
  });
};

const setPricingApproveList = (state, action) => {
  if (action.list === 'negotiation') {
    return updateObject(state, {
      ...state,
      negotiationList: action.pricingList,
      loading: false
    });
  } else if (action.list === 'renegotiation') {
    return updateObject(state, {
      ...state,
      renegotiationList: action.pricingList,
      loading: false
    });
  } else if (action.list === 'legacy-negotiation') {
    return updateObject(state, {
      ...state,
      oldNegotiationList: action.pricingList,
      loading: false
    });
  } else if (action.list === 'legacy-renegotiation') {
    return updateObject(state, {
      ...state,
      oldRenegotiationList: action.pricingList,
      loading: false
    });
  } else if (action.list === 'prepayment') {
    return updateObject(state, {
      ...state,
      prepaymentList: action.pricingList,
      loading: false
    });
  } else if (action.list === 'legacy-prepayment') {
    return updateObject(state, {
      ...state,
      oldPrepaymentList: action.pricingList,
      loading: false
    });
  }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.INIT_PRICING_APPROVE_LIST:
      return initPricingApproveList(state, action);
    case actionTypes.SET_PENDING_APPROVE_LIST:
      return setPricingApproveList(state, action);
    default:
      return state;
  }
};

export default reducer;
