import Cookies from 'universal-cookie';

import * as axios from './axiosRequests';

// const SALES_ANALYTICS_API_URL = process.env.REACT_APP_SALES_ANALYTICS_API_URL;
const SALES_ANALYTICS_API_URL = 'https://sales-analytics-api.azurewebsites.net';

export const getPublicUrl = () => {
  return `${window.location.origin}`;
};

export const getOauthSalesforceToken = async (code) => {
  const url = `${SALES_ANALYTICS_API_URL}/oauth/salesforce/token`;
  return await axios.runPostRequest(url, { code, redirect_uri: redirectUri }, {});
};

export const getOauthSalesforceConfigurations = async () => {
  const url = `${SALES_ANALYTICS_API_URL}/oauth/salesforce/configurations`;
  return await axios.runGetRequest(url, {}, {});
};

export const redirectUri = `${getPublicUrl()}/oauth/salesforce/callback`;

export const setSessionId = (sessionId) => {
  const cookies = new Cookies();
  cookies.set('ssid', sessionId, { path: '/', sameSite: 'none', secure: true });
  return getSessionId();
};

export const clearSessionId = () => {
  const cookies = new Cookies();
  cookies.remove('ssid', { path: '/' });
  return getSessionId();
};

export const getSessionId = () => {
  const cookies = new Cookies();
  const ssid = cookies.get('ssid');

  if (ssid === 'undefined') {
    return null;
  }
  return ssid;
};

export const startLogin = async () => {
  try {
    const {
      data: { clientId, url }
    } = await getOauthSalesforceConfigurations();

    const params = new URLSearchParams({
      client_id: clientId,
      redirect_uri: redirectUri,
      response_type: 'code'
    });

    window.open(`${url}/services/oauth2/authorize?${params.toString()}`, '_self');
  } catch (error) {
    console.error(error);
  }
};
