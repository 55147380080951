export const GET_APP_CONFIG = 'GET_APP_CONFIG';
export const SET_APP_CONFIG = 'SET_APP_CONFIG';

export const SET_OPPORTUNITY = 'SET_OPPORTUNITY';
export const SET_NEG_CONTRACT = 'SET_NEG_CONTRACT';
export const SET_NEG_CONTRACT_PENDING = 'SET_NEG_CONTRACT_PENDING';
export const SET_INFOPLEX = 'SET_INFOPLEX';
export const SET_PROMOTIONS = 'SET_PROMOTIONS';
export const SET_RENEGOTIATION_ADDRESS = 'SET_RENEGOTIATION_ADDRESS';
export const SET_CONFIGURATIONS = 'SET_CONFIGURATIONS';

export const INIT_PRICING_APPROVE_LIST = 'INIT_PRICING_APPROVE_LIST';
export const SET_PENDING_APPROVE_LIST = 'SET_PENDING_APPROVE_LIST';
export const SET_NEGOTIATION_PARAMS = 'SET_NEGOTIATION_PARAMS';
export const REFRESH_NEGOTIATION_PARAMS = 'REFRESH_NEGOTIATION_PARAMS';
export const SET_NEGOTIATION_PRICING_HISTORY = 'SET_NEGOTIATION_PRICING_HISTORY';

export const UPDATE_OPPORTUNITY = 'UPDATE_OPPORTUNITY';
export const UPDATE_PRICING = 'UPDATE_PRICING';
export const UPDATE_PRICING_BUNDLE = 'UPDATE_PRICING_BUNDLE';

export const INIT_FAST_SIMULATION = 'INIT_FAST_SIMULATION';
export const SET_FAST_SIMULATION_PRICING = 'SET_FAST_SIMULATION_PRICING';

export const INIT_PRICING_ACTION = 'INIT_PRICING_ACTION';
export const SET_CHAMPION_PRICE = 'SET_CHAMPION_PRICE';

export const INIT_CAP_METHOD_ACTION = 'INIT_CAP_METHOD_ACTION';
export const SET_CAP_METHODS = 'SET_CAP_METHODS';
export const FETCH_CAP_METHOD_FAILED = 'FETCH_CAP_METHOD_FAILED';

export const FETCH_NEG_API_FAILED = 'FETCH_NEG_API_FAILED';
export const FETCH_NEG_API_ALERT = 'FETCH_NEG_API_ALERT';
export const UPDATE_ERROR_STATUS = 'UPDATE_ERROR_STATUS';

export const NEG_SHARE_LOADING = 'NEG_SHARE_LOADING';

export const UPDATE_CHAIN_STONECODE = 'UPDATE_CHAIN_STONECODE';
export const SET_CHAINING_PRICING = 'SET_CHAINING_PRICING';

export const INIT_ADDRESS_ACTION = 'INIT_ADDRESS_ACTION';
export const INIT_RESIDENTIAL_ADDRESS_ACTION = 'INIT_RESIDENTIAL_ADDRESS_ACTION';
export const INIT_BANKING_ACTION = 'INIT_BANKING_ACTION';
export const INIT_CLIENT_ACTION = 'INIT_CLIENT_ACTION';

export const SET_AFFILIATION_PARTNER = 'SET_AFFILIATION_PARTNER';

export const INIT_AFFILIATE_ACTION = 'INIT_AFFILIATE_ACTION';
export const SET_AFFILIATE_RESULT = 'SET_AFFILIATE_RESULT';
export const INIT_GET_AFFILIATION_ACTION = 'INIT_GET_AFFILIATION_ACTION';
export const RENEG_INIT_CAP_METHOD_ACTION = 'RENEG_INIT_CAP_METHOD_ACTION';

export const SET_AFFILIATIONS_PARAMS = 'SET_AFFILIATIONS_PARAMS';
export const REFRESH_AFFILIATION_PARAMS = 'REFRESH_AFFILIATION_PARAMS';

export const SET_SUCCESS_FEEDBACK = 'SET_SUCCESS_FEEDBACK';
export const SET_ERROR_FEEDBACK = 'SET_ERROR_FEEDBACK';
export const UPDATE_FEEDBACK_MESSAGE = 'UPDATE_FEEDBACK_MESSAGE';

export const SET_AFFILIATION_DATA = 'SET_AFFILIATION_DATA';
export const UPDATE_AFFILIATION_DATA = 'UPDATE_AFFILIATION_DATA';
export const IS_AFFILIATION_DATA_LOADING = 'IS_AFFILIATION_DATA_LOADING';
export const UPDATE_BANKING_ACCOUNT = 'UPDATE_BANKING_ACCOUNT';
export const SET_STONE_DOMICILE = 'SET_STONE_DOMICILE';
export const SET_VERIFICATION_EMAIL_DATA = 'SET_VERIFICATION_EMAIL_DATA';

export const SET_INIT_NEG_ERROR = 'SET_INIT_NEG_ERROR';

export const SET_BANKING_STATUS = 'SET_BANKING_STATUS';

export const SET_CONTRACT_STATUS = 'SET_CONTRACT_STATUS';

export const SET_LOGISTICS_SLA = 'SET_LOGISTICS_SLA';

export const SET_BANKING_LIST = 'SET_BANKING_LIST';
export const SET_ADDRESS_DATA = 'SET_ADDRESS_DATA';
export const SET_RESIDENTIAL_ADDRESS_DATA = 'SET_RESIDENTIAL_ADDRESS_DATA';
export const SET_RENEGOTIATION_CONTACTS = 'SET_RENEGOTIATION_CONTACTS';
export const INIT_ADDRESS_LOADING = 'INIT_ADDRESS_LOADING';

export const SET_LIFE_INSURANCE_STATUS = 'SET_LIFE_INSURANCE_STATUS';
export const SET_STORE_INSURANCE_STATUS = 'SET_STORE_INSURANCE_STATUS';

export const SET_BANKING_ERROR = 'SET_BANKING_ERROR';

export const SET_EDIT_MODE = 'SET_EDIT_MODE';

export const SET_RENEG_INFOPLEX = 'SET_RENEG_INFOPLEX';
export const SET_ACCOUNT_DATA = 'SET_ACCOUNT_DATA';
export const SET_ACCOUNT_PROMOTIONS = 'SET_ACCOUNT_PROMOTIONS';
export const SET_RENEGOTIATION_PRICING_HISTORY = 'SET_RENEGOTIATION_PRICING_HISTORY';

export const SET_INIT_RENEG_ERROR = 'SET_INIT_RENEG_ERROR';

export const UPDATE_ACCOUNT_PRICING = 'UPDATE_ACCOUNT_PRICING';
export const UPDATE_ACCOUNT = 'UPDATE_ACCOUNT';

export const INIT_ACCOUNT_PRICING_ACTION = 'INIT_ACCOUNT_PRICING_ACTION';
export const SET_ACCOUNT_CHAMPION_PRICE = 'SET_ACCOUNT_CHAMPION_PRICE';

export const FETCH_RENEG_API_FAILED = 'FETCH_RENEG_API_FAILED';
export const FETCH_RENEG_API_ALERT = 'FETCH_RENEG_API_ALERT';
export const UPDATE_ACCOUNT_ERROR_STATUS = 'UPDATE_ACCOUNT_ERROR_STATUS';
export const SET_ACCOUNT_CHAINING_PRICING = 'SET_ACCOUNT_CHAINING_PRICING';
export const UPDATE_ACCOUNT_CHAIN_STONECODE = 'UPDATE_ACCOUNT_CHAIN_STONECODE';

export const INIT_RENEG_FAST_SIMULATION = 'INIT_RENEG_FAST_SIMULATION';
export const SET_FAST_SIMULATION_RENEG = 'SET_FAST_SIMULATION_RENEG';

export const SET_RENEGOTIATION_CHAMPION_PRICE = 'SET_RENEGOTIATION_CHAMPION_PRICE';

export const SET_RENEG_CONTRACT_PENDING = 'SET_RENEG_CONTRACT_PENDING';
export const SET_RENEG_RICH_CONTRACT_PENDING = 'SET_RENEG_RICH_CONTRACT_PENDING';
export const SET_RENEG_RICH_CONTRACT = 'SET_RENEG_RICH_CONTRACT';
export const SET_ACCOUNT_CONTRACT = 'SET_ACCOUNT_CONTRACT';
export const SET_RENEG_CAP_METHODS = 'SET_RENEG_CAP_METHODS';

export const SET_RENEGOTIATION_PARAMS = 'SET_RENEGOTIATION_PARAMS';
export const REFRESH_RENEGOTIATION_PARAMS = 'REFRESH_RENEGOTIATION_PARAMS';
export const SET_HISTORIC_RENEGOTIATION = 'SET_HISTORIC_RENEGOTIATION';
export const UNSET_HISTORIC_RENEGOTIATION = 'UNSET_HISTORIC_RENEGOTIATION';

export const SET_RENEGOTIATION_SUCCESS = 'SET_RENEGOTIATION_SUCCESS';
export const DISMISS_RENEGOTIATION_SUCCESS = 'DISMISS_RENEGOTIATION_SUCCESS';

export const UPDATE_ACCOUNT_PRICING_BUNDLE = 'UPDATE_ACCOUNT_PRICING_BUNDLE';

export const INIT_INFOPLEX = 'INIT_INFOPLEX';

export const SET_TOTAL_INCOME_RANGE = 'SET_TOTAL_INCOME_RANGE';
export const SET_TOTAL_REVENUE_RANGE = 'SET_TOTAL_REVENUE_RANGE';
export const SET_PROFESSIONS = 'SET_PROFESSIONS';
