import { create } from 'zustand';
import * as SalesAuthenticator from '../../utils/services/salesAuthenticator';

const useAuth = create((set) => ({
  sessionId: undefined,
  exited: false,
  setSessionId: (sessionId) =>
    set((state) => {
      return {
        ...state,
        sessionId
      };
    }),
  logout: () =>
    set((state) => {
      SalesAuthenticator.clearSessionId();
      return {
        ...state,
        sessionId: undefined,
        exited: true
      };
    })
}));

export default useAuth;
