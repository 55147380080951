import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
  isAffiliationDataLoading: true,
  opportunity: undefined,
  bankList: undefined,
  salesforceSessionId: null,
  infoplex: undefined,
  loading: false,
  loadInfoplex: false,
  loadAddress: false,
  loadResidentialAddress: false,
  loadBanking: false,
  loadClient: false,
  credSuccess: undefined,
  stonecode: undefined,
  success: undefined,
  error: undefined,
  bankingStatus: undefined,
  bankingAccount: undefined,
  contractStatus: undefined,
  contractType: undefined,
  logisticsSla: undefined,
  editMode: false,
  isPartner: false,
  contaStoneFailed: undefined,
  verificationData: null,
  lifeInsuranceData: null,
  storeInsuranceData: null,
  professions: [],
  totalIncomeRange: [],
  totalRevenueRange: []
};

const setAffiliationData = (state, action) => {
  return updateObject(state, {
    salesforceSessionId: action.salesforceSessionId,
    opportunity: action.opportunity,
    loadAddress: false,
    loadResidentialAddress: false,
    loadBanking: false,
    loadClient: false,
    refreshLoading: false,
    loading: false,
    stonecode: undefined,
    credSuccess: undefined
  });
};

const setAffiliationParams = (state, action) => {
  return updateObject(state, {
    ...state,
    salesforceSessionId: action.salesforceSessionId,
    opportunityId: action.opportunityId,
    opportunity: undefined
  });
};

const refreshAffiliationParams = (state, action) => {
  return updateObject(state, {
    ...state,
    opportunityId: undefined
  });
};

const setBankingList = (state, action) => {
  return updateObject(state, {
    ...state,
    bankList: action.bankList
  });
};

const setAddressData = (state, action) => {
  return updateObject(state, {
    ...state,
    opportunity: {
      ...state.opportunity,
      Rua__c: action.data.street,
      Bairro__c: action.data.neighbourhood,
      Cidade__c: action.data.city,
      Estado__c: action.data.state
    },
    loadAddress: false
  });
};

const setResidentialAddressData = (state, action) => {
  return updateObject(state, {
    ...state,
    opportunity: {
      ...state.opportunity,
      Rua_Residencial__c: action.data.street,
      Bairro_Residencial__c: action.data.neighbourhood,
      Cidade_Residencial__c: action.data.city,
      Estado_Residencial__c: action.data.state
    },
    loadResidentialAddress: false
  });
};

const setVerificationEmailData = (state, action) => {
  return updateObject(state, {
    ...state,
    verificationData: action.data
  });
};

const setSuccessStatus = (state, action) => {
  return updateObject(state, {
    ...state,
    success: action.success,
    loadAddress: false,
    loadResidentialAddress: false,
    loadBanking: false,
    loadClient: false,
    loading: false,
    loadInfoplex: false
  });
};

const setErrorStatus = (state, action) => {
  return updateObject(state, {
    ...state,
    error: action.error,
    loadAddress: false,
    loadResidentialAddress: false,
    loadBanking: false,
    loadClient: false,
    loading: false,
    loadInfoplex: false
  });
};

const setProfessions = (state, action) => {
  return updateObject(state, {
    ...state,
    professions: action.professions
  });
};

const setTotalIncomeRange = (state, action) => {
  return updateObject(state, {
    ...state,
    totalIncomeRange: action.totalIncomeRange
  });
};

const setTotalRevenueRange = (state, action) => {
  return updateObject(state, {
    ...state,
    totalRevenueRange: action.totalRevenueRange
  });
};

const setBankingStatus = (state, action) => {
  let status = 'Não criado';
  let account = null;
  let hasAccount = false;
  if (action.success === false) {
    status = 'sem dados';
    return updateObject(state, {
      ...state,
      bankingStatus: status,
      bankingAccount: account
    });
  }

  if (action.data !== null) {
    status = action.data.account
      ? 'Criado'
      : action.data.user || action.data.org
      ? 'Em análise'
      : 'Não criado';

    account = action.data.account;
    hasAccount = true;
  }

  // if (action.bank === '197-OpenBank Stone') {
  //   status = 'Domicilio';
  //   account = action.data ? action.data.account : null;
  //   hasAccount = true;
  // }

  return updateObject(state, {
    ...state,
    opportunity: {
      ...state.opportunity,
      tentou_abrir_conta_stone__c: hasAccount
    },
    bankingStatus: status,
    bankingAccount: account
  });
};

const setContractStatus = (state, action) => {
  let type = action.contract;
  if (type === 'Sem termo') {
    return updateObject(state, {
      ...state,
      contractType: 'Sem termo',
      contractStatus: 'Sem termo'
    });
  }

  if (action.success === false) {
    return updateObject(state, {
      ...state,
      contractType: 'sem dados',
      contractStatus: 'sem dados'
    });
  }

  let status = '';
  if (action.data.message === 'Nenhum contrato identificado') {
    status = 'Sem contrato';
    type = 'Sem contrato';
  } else {
    status = action.data.data.status;
    if (action.data.data.contractType === 'Contrato de Incentivo') {
      type = 'Com Multa';
    } else if (action.data.data.contractType === 'Termo de Incentivo') {
      type = 'Sem Multa';
    } else if (action.data.data.contractType === 'Contrato Isenção por Volume sem Multa') {
      type = 'IpV - Sem Multa';
    } else if (action.data.data.contractType === 'Contrato Isenção por Volume com Multa') {
      type = 'IpV - Com Multa';
    } else if (action.data.data.contractType === 'Contrato Stone Fast com Multa') {
      type = 'Fast - Com Multa';
    } else if (action.data.data.contractType === 'Contrato Stone Fast sem Multa') {
      type = 'Fast - Sem Multa';
    } else if (action.data.data.contractType === 'Contrato Stone Fast com Multa sem IpV') {
      type = 'Fast s/ IpV - Com Multa';
    } else if (action.data.data.contractType === 'Contrato Stone Fast sem Multa sem IpV') {
      type = 'Fast s/ IpV - Sem Multa';
    } else if (action.data.data.contractType === 'Contrato Sazonal com Multa com IpV') {
      type = 'Sazonal c/ IpV - Com Multa';
    } else if (action.data.data.contractType === 'Contrato Sazonal com Multa') {
      type = 'Sazonal - Com Multa';
    } else if (action.data.data.contractType === 'Contrato Mdrzao sem Multa') {
      type = 'Mdrzao - Sem Multa';
    } else if (action.data.data.contractType === 'Contrato Mdrzao com Multa') {
      type = 'Mdrzao - com Multa';
    }
  }

  return updateObject(state, {
    ...state,
    contractType: type,
    contractStatus: status
  });
};

const setLogisticsSla = (state, action) => {
  let sla = 'sem dados';
  if (action.success === false) {
    sla = 'sem dados';
    return updateObject(state, {
      ...state,
      logisticsSla: sla
    });
  }

  return updateObject(state, {
    ...state,
    logisticsSla: action.data.data[0].sla
  });
};

const setLifeInsuranceStatus = (state, action) => {
  if (action.success === false) {
    return updateObject(state, {
      ...state,
      lifeInsuranceData: null
    });
  }

  return updateObject(state, {
    ...state,
    lifeInsuranceData: action.data.contracts
  });
};

const setStoreInsuranceStatus = (state, action) => {
  if (action.success === false) {
    return updateObject(state, {
      ...state,
      storeInsuranceData: null
    });
  }

  return updateObject(state, {
    ...state,
    storeInsuranceData: action.data.contracts
  });
};

const setAffiliateResult = (state, action) => {
  return updateObject(state, {
    ...state,
    stonecode: action.data.stonecode,
    credSuccess: action.success,
    loading: false,
    loadAddress: false,
    loadResidentialAddress: false,
    loadBanking: false,
    loadClient: false
  });
};

const setEditMode = (state, action) => {
  return updateObject(state, {
    ...state,
    editMode: action.editMode
  });
};

const setBankingError = (state, action) => {
  return updateObject(state, {
    ...state,
    contaStoneFailed: action.error
  });
};

const setIsPartner = (state, action) => {
  return updateObject(state, {
    ...state,
    isPartner: action.partner
  });
};

const updateData = (state, action) => {
  return updateObject(state, {
    ...state,
    opportunity: {
      ...state.opportunity,
      [action.fieldName]: action.newValue
    }
  });
};

const updateBankingAccount = (state, action) => {
  return updateObject(state, {
    ...state,
    bankingAccount: action.newAccount,
    bankingStatus: 'Criado'
  });
};

const setStoneDomicile = (state, action) => {
  const lastDigit = action.accountNumber.slice(-1);
  const account = action.accountNumber.slice(0, action.accountNumber.length - 1);

  return {
    ...state,
    opportunity: {
      ...state.opportunity,
      Banco__c: '197-OpenBank Stone',
      Tipo_de_Conta__c: 'Conta Corrente',
      Digito_da_Agencia__c: null,
      Numero_da_Agencia__c: '0001',
      Numero_da_Conta__c: account,
      Digito_da_Conta__c: lastDigit,
      not_stone_domicile_reason__c: null,
      not_stone_domicile_justification__c: null
    }
  };
};

const updateFeedbackMessage = (state, action) => {
  return updateObject(state, {
    ...state,
    success: undefined,
    error: undefined
  });
};

const initAddress = (state, action) => {
  return updateObject(state, {
    ...state,
    loadAddress: true
  });
};

const initResidentialAddress = (state, action) => {
  return updateObject(state, {
    ...state,
    loadResidentialAddress: true
  });
};

const initBanking = (state, action) => {
  return updateObject(state, {
    ...state,
    loadBanking: true
  });
};

const initClient = (state, action) => {
  return updateObject(state, {
    ...state,
    loadClient: true
  });
};

const initInfoplex = (state, action) => {
  return updateObject(state, {
    ...state,
    loadInfoplex: true
  });
};

const initTryAffiliate = (state, action) => {
  return updateObject(state, {
    ...state,
    loading: true,
    loadAddress: true,
    loadResidentialAddress: true,
    loadBanking: true,
    loadClient: true
  });
};

const intGetAffiliationData = (state, action) => {
  return updateObject(state, {
    ...state,
    refreshLoading: true
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_AFFILIATION_DATA:
      return setAffiliationData(state, action);
    case actionTypes.SET_BANKING_LIST:
      return setBankingList(state, action);
    case actionTypes.SET_ADDRESS_DATA:
      return setAddressData(state, action);
    case actionTypes.SET_RESIDENTIAL_ADDRESS_DATA:
      return setResidentialAddressData(state, action);
    case actionTypes.SET_VERIFICATION_EMAIL_DATA:
      return setVerificationEmailData(state, action);
    case actionTypes.IS_AFFILIATION_DATA_LOADING:
      return { ...state, isAffiliationDataLoading: action.loading };
    case actionTypes.UPDATE_AFFILIATION_DATA:
      return updateData(state, action);
    case actionTypes.UPDATE_BANKING_ACCOUNT:
      return updateBankingAccount(state, action);
    case actionTypes.SET_STONE_DOMICILE:
      return setStoneDomicile(state, action);
    case actionTypes.INIT_ADDRESS_ACTION:
      return initAddress(state, action);
    case actionTypes.INIT_RESIDENTIAL_ADDRESS_ACTION:
      return initResidentialAddress(state, action);
    case actionTypes.INIT_BANKING_ACTION:
      return initBanking(state, action);
    case actionTypes.INIT_CLIENT_ACTION:
      return initClient(state, action);
    case actionTypes.INIT_INFOPLEX:
      return initInfoplex(state, action);
    case actionTypes.SET_SUCCESS_FEEDBACK:
      return setSuccessStatus(state, action);
    case actionTypes.SET_ERROR_FEEDBACK:
      return setErrorStatus(state, action);
    case actionTypes.UPDATE_FEEDBACK_MESSAGE:
      return updateFeedbackMessage(state, action);
    case actionTypes.SET_BANKING_STATUS:
      return setBankingStatus(state, action);
    case actionTypes.SET_PROFESSIONS:
      return setProfessions(state, action);
    case actionTypes.SET_TOTAL_INCOME_RANGE:
      return setTotalIncomeRange(state, action);
    case actionTypes.SET_TOTAL_REVENUE_RANGE:
      return setTotalRevenueRange(state, action);
    case actionTypes.SET_CONTRACT_STATUS:
      return setContractStatus(state, action);
    case actionTypes.SET_LOGISTICS_SLA:
      return setLogisticsSla(state, action);
    case actionTypes.SET_AFFILIATION_PARTNER:
      return setIsPartner(state, action);
    case actionTypes.INIT_AFFILIATE_ACTION:
      return initTryAffiliate(state, action);
    case actionTypes.SET_AFFILIATE_RESULT:
      return setAffiliateResult(state, action);
    case actionTypes.SET_EDIT_MODE:
      return setEditMode(state, action);
    case actionTypes.SET_BANKING_ERROR:
      return setBankingError(state, action);
    case actionTypes.SET_LIFE_INSURANCE_STATUS:
      return setLifeInsuranceStatus(state, action);
    case actionTypes.SET_STORE_INSURANCE_STATUS:
      return setStoreInsuranceStatus(state, action);
    case actionTypes.SET_INFOPLEX:
      return { ...state, loadInfoplex: false, infoplex: action.infoplexData };
    case actionTypes.INIT_GET_AFFILIATION_ACTION:
      return intGetAffiliationData(state, action);
    case actionTypes.SET_AFFILIATIONS_PARAMS:
        return setAffiliationParams(state, action);
    case actionTypes.REFRESH_AFFILIATION_PARAMS:
      return refreshAffiliationParams(state, action);
    default:
      return state;
  }
};

export default reducer;
