import _ from 'lodash';
import moment from 'moment';

export const handleFastSimulation = (state, po) => {
  if (state.fastSimulateStatus) {
    state.fastSimulateStatus = undefined;
    po['Pricing_Status__c'] = 'Novo';
    po['API_Margem_Percentual__c'] = null;
    po['API_Margem_Valor__c'] = null;
    po['API_NET_Geral_Credit_2x6__c'] = null;
    po['API_NET_Geral_Credit_7x12__c'] = null;
    po['API_NET_Geral_Credit__c'] = null;
    po['API_NET_Geral_Debit__c'] = null;
    po['API_NET_Total_Credit2x6__c'] = null;
    po['API_NET_Total_Credit7x12__c'] = null;
    po['API_NET_Total_Credit__c'] = null;
    po['API_NET_Total_Debit__c'] = null;
    po['API_Net_Credito_2x6_Amex__c'] = null;
    po['API_Net_Credito_2x6_Elo__c'] = null;
    po['API_Net_Credito_2x6_hiper__c'] = null;
    po['API_Net_Credito_7x12_Amex__c'] = null;
    po['API_Net_Credito_7x12_Elo__c'] = null;
    po['API_Net_Credito_7x12_hiper__c'] = null;
    po['API_Net_Credito_Amex__c'] = null;
    po['API_Net_Credito_Elo__c'] = null;
    po['API_Net_Credito_Hiper__c'] = null;
    po['API_Net_Debito_Elo__c'] = null;
    po['API_Payback__c'] = null;
    po['errorNewPricing__c'] = null;
    po['API_Lucro_Bruto__c'] = null;
    po['API_Receita_Total__c'] = null;
    po['API_Custo_Total__c'] = null;
    po['API_Custo_Mensalidade__c'] = null;
    po['Custo_RC__c'] = null;
    po['Custo_Logistica__c'] = null;
    po['API_Custo_Imposto_Mensalidade__c'] = null;
    po['API_Custo_RAV__c'] = null;
    po['API_Custo_Imposto_RAV__c'] = null;
    po['API_Custo_Transacional__c'] = null;
    po['API_Custo_Imposto_MDR__c'] = null;
    po['API_Receita_Mensalidade__c'] = null;
    po['API_Receita_RAV__c'] = null;
    po['API_Receita_Transacional__c'] = null;
  }
  return po;
};

export const handlePNLResult = (account, pnl) => {
  const isFranchise = account.currentUser.profile.id === '00e1L00000106ZFQAY';
  const isRetention =
    account.currentUser.profile.id === '00e1L000001GlaJQAS' ||
    account.currentUser.profile.id === '00e41000001m4wHAAQ' ||
    account.currentUser.profile.id === '00e3j000001IeLdAAK';
  const { pricingObject } = account;
  const po = pricingObject;

  if (!pnl) {
    return po;
  }

  // general
  po.API_Lucro_Bruto__c = pnl.stone_pnl.total_margin; // stone_pnl.total_margin
  po.API_Receita_Total__c = pnl.stone_pnl.total_revenue; // stone_pnl.total_revenue
  po.API_Custo_Total__c = pnl.stone_pnl.total_cost + pnl.stone_pnl.total_tax; // custo + imposto + custo rc + custo log
  po.API_Rating__c = pnl.rating ? pnl.rating.rating : null;
  po.RatingTiers__c = pnl.rating ? pnl.rating.ratingTiers : null;

  // custo
  po.API_Custo_Mensalidade__c = pnl.subscription_pnl.cost.total_cost; // ---> pensar oq fazer com isso
  po.Custo_RC__c = 6.0;
  po.Custo_Logistica__c = pnl.subscription_pnl.cost.total_cost - 6.0;
  po.API_Custo_Pix__c = pnl.pix_dynamic_qr_code_pnl.cost.total_cost;
  po.API_Custo_Imposto_Mensalidade__c = pnl.subscription_pnl.tax.total_tax;
  po.API_Custo_RAV__c = pnl.financial_pnl.cost.total_cost;
  po.API_Custo_Imposto_RAV__c = pnl.financial_pnl.tax.total_tax;
  po.API_Custo_Transacional__c = pnl.transactional_pnl.cost.total_cost; // total_cost + total_tax
  po.API_Custo_Imposto_MDR__c = pnl.transactional_pnl.tax.total_tax; //
  po.API_Custo_Imposto_Pix__c = pnl.pix_dynamic_qr_code_pnl.tax.total_tax;

  // receita
  po.API_Receita_Mensalidade__c = pnl.subscription_pnl.revenue.total_revenue; // subscription_pnl.revenue.total_revenue
  po.API_Receita_RAV__c = pnl.financial_pnl.revenue.total_revenue; // financial_pnl.revenue.total_revenue
  po.API_Receita_Transacional__c = pnl.transactional_pnl.revenue.total_revenue;
  po.API_Receita_Pix__c = pnl.pix_dynamic_qr_code_pnl.revenue.total_revenue;

  if (pnl.ratingType === 'monthly_3x' && !isFranchise && !isRetention) {
    const ifrs = pnl.ifrs;

    po.API_Receita_Mensalidade__c = (ifrs['1'].revenue + ifrs['2'].revenue + ifrs['3'].revenue) / 3;
    po.API_Custo_Imposto_Mensalidade__c = (ifrs['1'].tax + ifrs['2'].tax + ifrs['3'].tax) / 3;
    po.API_Custo_Mensalidade__c = (ifrs['1'].cost + ifrs['2'].cost + ifrs['3'].cost) / 3;
    po.API_Lucro_Bruto__c = pnl.rating.profit;

    po.API_Receita_Total__c =
      po.API_Receita_RAV__c +
      po.API_Receita_Transacional__c +
      po.API_Receita_Mensalidade__c +
      po.API_Receita_Pix__c;

    po.Custo_Logistica__c = po.API_Custo_Mensalidade__c - 6.0;

    po.API_Custo_Total__c =
      po.Custo_Logistica__c +
      po.Custo_RC__c +
      po.API_Custo_RAV__c +
      po.API_Custo_Transacional__c +
      po.API_Custo_Pix__c +
      po.API_Custo_Imposto_Mensalidade__c +
      po.API_Custo_Imposto_RAV__c +
      po.API_Custo_Imposto_MDR__c +
      po.API_Custo_Imposto_Pix__c;
  }

  return po;
};

export const handleChampionPriceMDR = (mdrs, po) => {
  if (mdrs.amex) {
    po.Credito_Vista_Amex__c = mdrs.amex.credit;
    po.Credito_2_a_6_Amex__c = mdrs.amex.credit2to6;
    po.Credito_7_a_12_Amex__c = mdrs.amex.credit7to12;
  }
  if (mdrs.elo) {
    po.Debito_EloSub__c = mdrs.elo.debit;
    po.Credito_a_Vista_EloSub__c = mdrs.elo.credit;
    po.Credito_2_a_6_EloSub__c = mdrs.elo.credit2to6;
    po.Credito_7_a_12_EloSub__c = mdrs.elo.credit7to12;
  }
  if (mdrs.hiper) {
    po.credito_a_vista_hiper__c = mdrs.hiper.credit;
    po.credito_2_a_6_hiper__c = mdrs.hiper.credit2to6;
    po.credito_7_a_12_hiper__c = mdrs.hiper.credit7to12;
  }
  if (mdrs.visa) {
    po.Debito__c = mdrs.visa.debit;
    po.Credito_a_Vista__c = mdrs.visa.credit;
    po.Credito_2_a_6__c = mdrs.visa.credit2to6;
    po.Credito_7_a_12__c = mdrs.visa.credit7to12;
  }
  return po;
};

export const championPriceBody = (account) => {
  const { pricingObject } = account;
  const po = pricingObject;

  let agreement = 'none';
  if (account.Tipo_de_termo__c === 'Termo sem multa') {
    agreement = 'without_fine';
  } else if (account.Tipo_de_termo__c === 'Termo com multa') {
    agreement = 'with_fine';
  } else if (account.Tipo_de_termo__c === 'Termo com multa 2 anos') {
    agreement = 'with_fine_2_years';
  }

  let document = 'cnpj';
  if (account.CNPJ__c.toString().length <= 11) {
    document = 'cpf';
  }

  const getTpvPix = (account) => {
    if (account.Tpv_Pix_Estimado__c) {
      return account.Tpv_Pix_Estimado__c;
    }
    return 0;
  };

  const completeBody = {
    mcc: account.MCC__c,
    tpv: account.TPV_Estimado__c + getTpvPix(account),
    migration: 100,
    automaticPrepaymentEnabled: po.Antecipacao_Automatica__c,
    isStoneFast: po.Stone_Fast__c,
    agreement: agreement,
    clientType: document
  };

  return completeBody;
};

function getExemptionMonths(exemptionDays) {
  const exemptionDaysNumber = typeof exemptionDays === 'number' ? exemptionDays : 0;
  return exemptionDaysNumber ? Math.floor(exemptionDaysNumber / 30) : 0;
}

export const formatPricingBody = (account, jsonReceita) => {
  const { pricingObject, captureMethods } = account;
  const po = pricingObject;

  const getCardTypeMDR = (key) => {
    return _.round(po[key] / 100, 4);
  };

  const formatCaptureMethods = () => {
    var included = [];
    var extras = [];
    captureMethods.map((cm) => {
      return extras.push({
        type: _.toLower(cm.Meio_de_Captura__c),
        quantity: cm.Quantidade__c,
        price: cm.Aluguel__c,
        exemptionDays: cm.Dias_de_Insecao__c === null ? 0 : cm.Dias_de_Insecao__c,
        exemptionMonths: getExemptionMonths(cm.Dias_de_Insecao__c)
      });
    });
    return { included, extras };
  };

  const formatCurrentCaptureMethods = () => {
    var included = [];
    var extras = [];

    const getCurrentExemptionDays = (capMethod) => {
      const now = moment();
      const endDate = moment(capMethod.Data_Fim_Isencao__c);

      if (!capMethod.Data_Fim_Isencao__c) {
        return 0;
      }
      if (endDate.diff(now, 'days') < 0) {
        return 0;
      }

      return endDate.diff(now, 'days');
    };

    captureMethods.map((cm) => {
      return extras.push({
        type: _.toLower(cm.Meio_de_Captura__c),
        quantity: cm.Quantidade__c || 1,
        price: cm.Atual_Aluguel__c || 0,
        exemptionDays: getCurrentExemptionDays(cm) || 0,
        exemptionMonths: getExemptionMonths(getCurrentExemptionDays(cm))
      });
    });
    return { included, extras };
  };

  const checkIsMei = (jsonReceita) => {
    const document = account.CNPJ__c?.toString();
    if (document?.length <= 11) {
      return 'cpf';
    }

    if (jsonReceita) {
      const natureza = jsonReceita.Natureza;
      const razao = jsonReceita.CompanyName;
      if (natureza === '213-5 - Empresário (Individual)') {
        const trailing11 = razao?.slice(-11);
        const trailing3 = razao?.slice(-3);

        if (trailing3.toLowerCase() === 'mei') return 'mei';
        if (/^\d+$/.test(trailing11)) return 'mei'; // All last 11 digits are numbers
      }
    }

    return 'cnpj';
  };

  const getTpvPix = (account) => {
    if (account.Tpv_Pix_Estimado__c) {
      return account.Tpv_Pix_Estimado__c;
    }
    return 0;
  };

  const completeBody = {
    prepayment: {
      frequency: po.Antecipacao_Periodicidade__c,
      automatic: {
        active: po.Antecipacao_Automatica__c,
        rate: _.round(po.Taxa_Automatica__c / 100, 4)
      },
      spot: _.round(po.Taxa_Spot__c / 100, 4),
      frequencyD0Fee: _.round((po.Taxa_Liquidacao_D0__c ?? 0) / 100, 4),
      frequencyD1Fee: _.round((po.Taxa_Liquidacao_D1__c ?? 0) / 100, 4),
      stoneFast: po.Stone_Fast__c
    },
    clientType: checkIsMei(jsonReceita),
    agreement: po.Tipo_de_termo__c || 'Sem termo',
    storeAmount: account.Numero_de_Lojas__c ? account.Numero_de_Lojas__c : 1,
    promotion: po.Campanhas__c || '0 - Sem Campanha',
    volumeExemption: po.Isencao_por_Volume__c,
    volumeExemptionCondition: po.Volume_Exemption_Condition__c,
    currentVolumeExemption: po.Atual_Isencao_por_Volume__c,
    captureMethods: formatCaptureMethods(),
    currentCaptureMethods: formatCurrentCaptureMethods(),
    mdrs: {
      visa: {
        credit: getCardTypeMDR('Credito_a_Vista__c'),
        credit2to6: getCardTypeMDR('Credito_2_a_6__c'),
        credit7to12: getCardTypeMDR('Credito_7_a_12__c'),
        debit: getCardTypeMDR('Debito__c')
      },
      master: {
        credit: getCardTypeMDR('Credito_a_Vista__c'),
        credit2to6: getCardTypeMDR('Credito_2_a_6__c'),
        credit7to12: getCardTypeMDR('Credito_7_a_12__c'),
        debit: getCardTypeMDR('Debito__c')
      },
      elo: {
        credit: getCardTypeMDR('Credito_a_Vista_EloSub__c'),
        credit2to6: getCardTypeMDR('Credito_2_a_6_EloSub__c'),
        credit7to12: getCardTypeMDR('Credito_7_a_12_EloSub__c'),
        debit: getCardTypeMDR('Debito_EloSub__c')
      },
      amex: {
        credit: getCardTypeMDR('Credito_Vista_Amex__c'),
        credit2to6: getCardTypeMDR('Credito_2_a_6_Amex__c'),
        credit7to12: getCardTypeMDR('Credito_7_a_12_Amex__c')
      },
      hiper: {
        credit: getCardTypeMDR('credito_a_vista_hiper__c'),
        credit2to6: getCardTypeMDR('credito_2_a_6_hiper__c'),
        credit7to12: getCardTypeMDR('credito_7_a_12_hiper__c')
      }
    },
    pix: {
      tpv: account.Tpv_Pix_Estimado__c || 0,
      rates: {
        capture: po.Taxa_Captura_Pix__c
      }
    },
    competitorInformation: {
      acquirer: po.Adquirente_Concorrencia__c,
      capture: po.Captura_Concorrencia__c,
      model: po.Modelo_Concorrencia__c,
      fees:
        po.Modelo_Concorrencia__c === 'MDRzão/Flex'
          ? {
              debit: po.Taxa_Debito_Concorrencia__c,
              credit: po.Taxa_Credito_Vista_Concorrencia__c,
              credit2to6: po.Taxa_Credito_Parcelado_Concorrencia__c,
              credit7to12: po.Taxa_Credito_Parcelado_Concorrencia__c
            }
          : {
              debit: po.Taxa_Debito_Concorrencia__c,
              credit: po.Taxa_Credito_Vista_Concorrencia__c,
              credit2to6: po.Taxa_Credito_2_a_6_Concorrencia__c,
              credit7to12: po.Taxa_Credito_7_a_12_Concorrencia__c
            },
      prepayment: {
        automatic:
          po.Modelo_Concorrencia__c === 'MDRzão/Flex'
            ? po.Taxa_RAV_a_Vista_Concorrencia__c
            : po.Taxa_Automatica_da_Concorrencia__c,
        spot:
          po.Modelo_Concorrencia__c === 'MDRzão/Flex'
            ? po.Taxa_RAV_Parcelado_Concorrencia__c
            : po.Taxa_Spot_da_Concorr_ncia__c
      },
      quantity: po.Qtdd_de_Maquinas_da_Concorrencia__c,
      rent: po.Aluguel_Concorrencia__c
    },
    installmentDistribution: {
      credit: po.share_de_credito__c / 100,
      credit2to6: po.share_de_credito_2_x_6__c / 100,
      credit7to12: po.share_de_credito_7_x_12__c / 100,
      debit: po.share_de_debito__c / 100
    },
    stone_domicile: po.Stone_Domicile__c,
    chainTpv: account.pricingObject.TPV_Cadeia__c,
    mcc: account.MCC__c,
    tpv: account.Enable_Tpv_Pix__c ? account.Tpv_Cartao_Estimado__c + getTpvPix(account) : account.TPV_Estimado__c,
    migration: account.Enable_Tpv_Pix__c ? 100 : account.Migracao__c,
    prepayment_edited: po.Prepayment_Edited__c,
    xRay:
      po.Raio_X__c === true
        ? {
            type: po.raio_x_digital__c === true ? 'digital' : 'physical',
            price: po.mensalidade_raio_x__c ? po.mensalidade_raio_x__c : 0
          }
        : null,
    collact:
      po.quer_collact__c === true
        ? {
            price: po.mensalidade_collact__c ? po.mensalidade_collact__c : 0
          }
        : null,
    currentPrepayment: {
      frequency: po.Atual_Antecipacao_Periodicidade__c,
      automatic: {
        active: po.Atual_Antecipacao_Automatica__c,
        rate: _.round(po.Atual_Taxa_Automatica__c / 100, 4)
      },
      spot: _.round(po.Atual_Taxa_Spot__c / 100, 4),
      stoneFast: po.Atual_Stone_Fast__c
    },
    currentPix: po.Atual_Taxa_Captura_Pix__c
      ? {
          rates: {
            capture: po.Atual_Taxa_Captura_Pix__c
          }
        }
      : null,
    currentMdrs: {
      visa: {
        credit: getCardTypeMDR('Atual_Credito_a_Vista__c'),
        credit2to6: getCardTypeMDR('Atual_Credito_2_a_6__c'),
        credit7to12: getCardTypeMDR('Atual_Credito_7_a_12__c'),
        debit: getCardTypeMDR('Atual_Debito__c')
      },
      master: {
        credit: getCardTypeMDR('Atual_Credito_a_Vista__c'),
        credit2to6: getCardTypeMDR('Atual_Credito_2_a_6__c'),
        credit7to12: getCardTypeMDR('Atual_Credito_7_a_12__c'),
        debit: getCardTypeMDR('Atual_Debito__c')
      },
      elo: {
        credit: getCardTypeMDR('Atual_Credito_a_Vista_EloSub__c'),
        credit2to6: getCardTypeMDR('Atual_Credito_2_a_6_EloSub__c'),
        credit7to12: getCardTypeMDR('Atual_Credito_7_a_12_EloSub__c'),
        debit: getCardTypeMDR('Atual_Debito_EloSub__c')
      },
      amex: {
        credit: getCardTypeMDR('Atual_Credito_Vista_Amex__c'),
        credit2to6: getCardTypeMDR('Atual_Credito_2_a_6_Amex__c'),
        credit7to12: getCardTypeMDR('Atual_Credito_7_a_12_Amex__c')
      },
      hiper: {
        credit: getCardTypeMDR('Atual_credito_a_vista_hiper__c'),
        credit2to6: getCardTypeMDR('Atual_credito_2_a_6_hiper__c'),
        credit7to12: getCardTypeMDR('Atual_credito_7_a_12_hiper__c')
      }
    }
  };

  return completeBody;
};

export const handleChainingProposal = (state, data) => {
  const live = state.account;
  const po = live.pricingObject;

  const installmentKeyLookup = {
    'Debito a vista': 1,
    'Credito a vista': 2,
    'Credito de 2 a 6 parcelas s/ juros': 3,
    'Credito de 7 a 12 parcelas s/ juros': 4
  };

  const brandKeyLookup = {
    Visa: 'visa',
    MasterCard: 'master',
    AmericanExpress: 'amex',
    Hipercard: 'hiper',
    Elo: 'elo'
  };

  const allowedBrands = [1, 2, 3, 9, 171];

  if (data) {
    // Fill MDRs fees
    data.cardFees.forEach((cardItem) => {
      var brand = cardItem.cardBrandName;
      if (!allowedBrands.includes(cardItem.cardBrandId)) {
        return '';
      }
      cardItem.fees.forEach((fee) => {
        const installmentKey = installmentKeyLookup[fee.transactionProfileName];

        let poKey = null;
        if (brandKeyLookup[brand] === 'visa' || brandKeyLookup[brand] === 'master') {
          if (installmentKey === 1) {
            poKey = 'Debito__c';
          } else if (installmentKey === 2) {
            poKey = 'Credito_a_Vista__c';
          } else if (installmentKey === 3) {
            poKey = 'Credito_2_a_6__c';
          } else if (installmentKey === 4) {
            poKey = 'Credito_7_a_12__c';
          }
        } else if (brandKeyLookup[brand] === 'amex') {
          if (installmentKey === 2) {
            poKey = 'Credito_Vista_Amex__c';
          } else if (installmentKey === 3) {
            poKey = 'Credito_2_a_6_Amex__c';
          } else if (installmentKey === 4) {
            poKey = 'Credito_7_a_12_Amex__c';
          }
        } else if (brandKeyLookup[brand] === 'hiper') {
          if (installmentKey === 2) {
            poKey = 'credito_a_vista_hiper__c';
          } else if (installmentKey === 3) {
            poKey = 'credito_2_a_6_hiper__c';
          } else if (installmentKey === 4) {
            poKey = 'credito_7_a_12_hiper__c';
          }
        } else if (brandKeyLookup[brand] === 'elo') {
          if (installmentKey === 1) {
            poKey = 'Debito_EloSub__c';
          } else if (installmentKey === 2) {
            poKey = 'Credito_a_Vista_EloSub__c';
          } else if (installmentKey === 3) {
            poKey = 'Credito_2_a_6_EloSub__c';
          } else if (installmentKey === 4) {
            poKey = 'Credito_7_a_12_EloSub__c';
          }
        }
        if (poKey) {
          po[poKey] = fee.rate;
        }
      });
    });

    // fill volume exemption condition
    var volumeExemption = data.volumeExemption;
    po.Isencao_por_Volume__c = volumeExemption.isVolumeExemption;

    // fill antecipation
    var prepayment = data.prepayment;
    po.Taxa_Automatica__c = prepayment.automaticRate;
    po.Stone_Fast__c = prepayment.advanceRateType === 3;
    po.Taxa_Spot__c = prepayment.spotRate;
    po.Antecipacao_Automatica__c = prepayment.prepaymentEnabled;

    // fill pix fee
    var products = data.products;
    po.Taxa_Captura_Pix__c = products.pix.dynamic;
  }
  return po;
};
