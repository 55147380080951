import React, { Suspense, lazy } from 'react';
import { LOGIN_URL, AUTH_CALLBACK_URL } from '../constants/routes';
import { Switch, Route } from 'react-router-dom';

const Home = lazy(() => import('./Home/Home'));
const HubNegotiation = lazy(() => import('./Negotiation/HubNegotiation'));
const PricingList = lazy(() => import('./PricingApproveList/PricingList'));
const HubContracts = lazy(() => import('./Contracts/HubContracts'));
const HubVoiceContracts = lazy(() => import('./VoiceContracts/HubVoiceContracts'));
const HubSaleVoiceContracts = lazy(() => import('./VoiceContracts/HubSaleVoiceContracts'));
const HubAffiliation = lazy(() => import('./Affiliation/HubAffiliation'));
const HubRenegotiation = lazy(() => import('./Renegotiation/HubRenegotiation'));
const Proposals = lazy(() => import('./Proposals/Proposals'));
// const GroupNegotiation = lazy(() => import('./GroupNegotiation/GroupNegotiation'));
const GroupOpportunity = lazy(() => import('./GroupOpportunity/GroupOpportunity'));
const AuthLogin = lazy(() => import('./Auth/Login'));
const AuthCallback = lazy(() => import('./Auth/Callback'));

const Routes = () => (
  <Suspense fallback={<div>Carregando..</div>}>
    <Switch>
      <Route exact path="/" component={Home} />

      <Route path="/negotiation" component={HubNegotiation} />
      <Route path="/pricing-list" component={PricingList} />
      <Route path="/contracts" component={HubContracts} />
      <Route path="/voice-contracts" component={HubVoiceContracts} />
      <Route path="/sale-voice-contracts" component={HubSaleVoiceContracts} />
      <Route path="/affiliation" component={HubAffiliation} />
      <Route path="/renegotiation" component={HubRenegotiation} />
      <Route path="/proposals/:accountId" component={Proposals} />
      {/* <Route path="/group-negotiation/:groupId" component={GroupNegotiation} /> */}
      <Route path="/group-negotiation" component={GroupOpportunity} />

      <Route path="/test-localhost" component={AuthLogin} />
      <Route path={LOGIN_URL} component={AuthLogin} />
      <Route path={AUTH_CALLBACK_URL} component={AuthCallback} />
    </Switch>
  </Suspense>
);

export default Routes;
