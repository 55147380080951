import React, { Fragment } from 'react';
import { hot } from 'react-hot-loader/root';
import { ConfigProvider } from 'antd';
import ptBR from 'antd/lib/locale/pt_BR';

import Routes from './routes/routes';
import useAuth from './store/actions/auth';
import RoutesController from './controllers/routes.controller';
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import * as salesAuthenticatorByCookies from './utils/services/salesAuthenticator';

const App = () => {
  const auth = useAuth();
  const sessionId = auth.sessionId;
  const exited = auth.exited;
  const setSessionId = auth.setSessionId;
  const history = useHistory();
  const cookieSessionId = salesAuthenticatorByCookies.getSessionId();
  const location = useLocation();

  useEffect(() => {
    const eventListenerFn = (event) => {
      if (event?.data?.call === 'sfSendValue') {
        if (event.data.value?.split(';').length > 0) {
          const sessionId = event.data.value.split(';')[2];
          salesAuthenticatorByCookies.setSessionId(sessionId);
          setSessionId(sessionId);
        }
      }
    };

    const eventListener = window.addEventListener('message', eventListenerFn);

    const init = async () => {
      try {
        const ControllerConfig = {
          cookieSessionId,
          exited,
          history,
          pathname: location.pathname,
          setSessionId,
          sessionId
        };

        const startController = RoutesController(ControllerConfig).mount;
        await startController();
      } catch (error) {
        console.error(error);
      }
    };

    init();
    return () => {
      if (eventListener) {
        window.removeEventListener('message', eventListener);
      }
    };
  }, [cookieSessionId, history, location.pathname, exited, sessionId, setSessionId]);

  return (
    <Fragment>
      <ConfigProvider locale={ptBR}>
        <Routes />
      </ConfigProvider>
    </Fragment>
  );
};

export default process.env.NODE_ENV === 'development' ? hot(App) : App;
