import axios from 'axios';

export const runGetDownloadRequest = (url, params, headers) => {
  return new Promise((resolve, reject) => {
    axios
      .get(url, { params, headers, responseType: 'blob' })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const runGetRequest = (url, params, headers) => {
  return new Promise((resolve, reject) => {
    axios
      .get(url, { params, headers })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const runPatchRequests = (url, body, headers) => {
  return new Promise((resolve, reject) => {
    axios
      .patch(url, body, { headers })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const runPutRequests = (url, body, headers) => {
  return new Promise((resolve, reject) => {
    axios
      .put(url, body, { headers })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const runPostRequest = (url, body, headers, params = {}) => {
  return new Promise((resolve, reject) => {
    axios
      .post(url, body, { params, headers })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const runDeleteRequest = (url, params, headers) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(url, { params, headers })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
