import * as actionTypes from '../actions/actionTypes';
import * as RenegotiationHelper from '../../utils/helpers/RenegotiationHelper';

import { updateObject } from '../../shared/utility';

const initialState = {
  salesforceSessionId: null,
  account: undefined,
  accountId: undefined,
  availablePromotions: ['0 - Sem Campanha'],
  pricingHistory: [],
  infoplexData: {},
  addresses: null,
  contacts: null,
  loading: false,
  loadAddress: false,
  loadCap: false,
  loadCapBtn: false,
  error: false,
  alert: false,
  initError: false,
  is404: false,
  success: false,
  message: undefined,
  chainStonecode: null,
  fastSimulateStatus: undefined,
  contract: null,
  tag: null
};

const setAccountData = (state, action) => {
  if (action.data.pricingObject.Xml_Pricing__c) {
    RenegotiationHelper.handlePNLResult(action.data, action.data.pricingObject.Xml_Pricing__c);
  }
  return updateObject(state, {
    account: action.data,
    accountId: action.data.Id,
    salesforceSessionId: action.salesforceSessionId,
    tag: action.data.Encadeamento__r ? 'Encadeado' : null,
    loading: false,
    loadCap: false,
    loadCapBtn: false,
    initError: false,
    is404: false,
    fastSimulateStatus: undefined
  });
};

const setAccountChainingPricing = (state, action) => {
  const pricing = action.pricing;
  const live = state.account;
  const po = live.pricingObject;

  RenegotiationHelper.handleChainingProposal(state, pricing);
  return updateObject(state, {
    ...state,
    account: {
      ...state.account,
      pricingObject: {
        ...po
      }
    },
    loading: false,
    loadCap: false,
    loadCapBtn: false,
    tag: 'Encadeado'
  });
};

const setInfoplexData = (state, action) => {
  return updateObject(state, {
    infoplexData: action.infoplexData
  });
};

const setRenegCapMethods = (state, action) => {
  return updateObject(state, {
    ...state,
    account: {
      ...state.account,
      captureMethods: action.capMethod
    },
    loading: false,
    error: false,
    loadCap: false,
    loadCapBtn: false
  });
};

const setRenegotiationPricingHistory = (state, action) => {
  return updateObject(state, {
    ...state,
    pricingHistory: action.data,
    loading: false,
    error: false
  });
};

const setInitialRenegotiationError = (state, action) => {
  return updateObject(state, {
    initError: true,
    is404: action.is404,
    loading: false
  });
};

const setContacts = (state, action) => {
  return (
    state,
    {
      ...state,
      contacts: action.contacts
    }
  );
};

const setAddresses = (state, action) => {
  return updateObject(state, {
    ...state,
    addresses: action.addresses,
    loadAddress: false
  });
};

const setNegotiationParams = (state, action) => {
  return updateObject(state, {
    ...state,
    salesforceSessionId: action.salesforceSessionId,
    accountId: action.accountId,
    account: undefined
  });
};

const setRenegotiationSuccess = (state, action) => {
  return updateObject(state, {
    success: true,
    loading: false
  });
};

const dismissRenegotationSuccess = (state, action) => {
  return updateObject(state, {
    success: false,
    loading: false
  });
};

const refreshRenegotiationParams = (state, action) => {
  return updateObject(state, {
    ...state,
    accountId: undefined
  });
};

const setContractPending = (state) => {
  return { ...state, contractPending: true };
};

const setRichContractPending = (state) => ({ ...state, richContractPending: true });

const setContractData = (state, action) => {
  const live = state.account;
  const po = live.pricingObject;

  let contractType = state.account.pricingObject.Tipo_de_Termo__c;
  if (action.contract && !state.account.pricingObject.Tipo_de_Termo__c) {
    contractType = action.contract.term_type;
  }
  return updateObject(state, {
    contract: action.contract,
    contractPending: false,
    account: {
      ...state.account,
      pricingObject: {
        ...po,
        Tipo_de_Termo__c: contractType
      }
    }
  });
};

const setRichContract = (state, action) => ({
  ...state,
  richContractPending: false,
  richContract: action.contract
});

const setFastSimulationPricing = (state, action) => {
  return updateObject(state, {
    ...state,
    account: {
      ...state.account,
      pricingObject: {
        ...action.pricing
      }
    },
    loading: false,
    loadCap: false,
    loadCapBtn: false,
    fastSimulateStatus: action.status
  });
};

const updateAccountPricing = (state, action) => {
  const live = state.account;
  const po = live.pricingObject;

  RenegotiationHelper.handleFastSimulation(state, po);
  if (action.fieldName === 'Stone_Fast__c') {
    if (action.newValue === true) {
      return updateObject(state, {
        ...state,
        loadCap: false,
        loadCapBtn: false,
        fastSimulateStatus: undefined,
        account: {
          ...state.account,
          pricingObject: {
            ...po,
            Antecipacao_Automatica__c: true,
            [action.fieldName]: action.newValue
          }
        }
      });
    }
  }

  if (action.propagateFeeChanges) {
    if (action.fieldName === 'Credito_a_Vista_EloSub__c') {
      return {
        ...state,
        fastSimulateStatus: undefined,
        account: {
          ...state.account,
          pricingObject: {
            ...po,
            [action.fieldName]: action.newValue,
            Credito_Vista_Amex__c: action.newValue,
            credito_a_vista_hiper__c: action.newValue
          }
        }
      };
    }

    if (action.fieldName === 'Credito_2_a_6_EloSub__c') {
      return {
        ...state,
        fastSimulateStatus: undefined,
        account: {
          ...state.account,
          pricingObject: {
            ...po,
            [action.fieldName]: action.newValue,
            Credito_2_a_6_Amex__c: action.newValue,
            credito_2_a_6_hiper__c: action.newValue
          }
        }
      };
    }

    if (action.fieldName === 'Credito_7_a_12_EloSub__c') {
      return {
        ...state,
        fastSimulateStatus: undefined,
        account: {
          ...state.account,
          pricingObject: {
            ...po,
            [action.fieldName]: action.newValue,
            Credito_7_a_12_Amex__c: action.newValue,
            credito_7_a_12_hiper__c: action.newValue
          }
        }
      };
    }
  }

  return updateObject(state, {
    ...state,
    fastSimulateStatus: false,
    account: {
      ...state.account,
      pricingObject: {
        ...po,
        [action.fieldName]: action.newValue
      }
    }
  });
};

const updateAccountPricingBundle = (state, action) => {
  const live = state.account;
  const po = live.pricingObject;

  RenegotiationHelper.handleFastSimulation(state, po);
  return updateObject(state, {
    ...state,
    account: {
      ...state.account,
      pricingObject: {
        ...po,
        ...action.pricing
      }
    },
    loading: false,
    loadCap: false,
    loadCapBtn: false
  });
};

const updateAccount = (state, action) => {
  return updateObject(state, {
    ...state,
    account: {
      ...state.account,
      [action.fieldName]: action.newValue
    }
  });
};

const updateChainStonecode = (state, action) => {
  return updateObject(state, {
    ...state,
    chainStonecode: action.stonecode
  });
};

const updateRenegotiationErrorStatus = (state, action) => {
  return updateObject(state, { error: false, alert: false, message: undefined });
};

const updateChampionPriceBundle = (state, action) => {
  const live = state.account;
  const po = live.pricingObject;

  RenegotiationHelper.handleFastSimulation(state, po);
  return updateObject(state, {
    ...state,
    account: {
      ...state.account,
      pricingObject: {
        ...po,
        ...action.pricing
      }
    },
    loading: false,
    loadCap: false,
    loadCapBtn: false
  });
};

const setAvailablePromotions = (state, action) => {
  return updateObject(state, {
    availablePromotions: action.data
  });
};

const initPricingAction = (state, action) => {
  return updateObject(state, {
    loading: true,
    loadCap: true,
    loadCapBtn: true
  });
};

const initAddressLoading = (state, action) => {
  return updateObject(state, {
    loadAddress: true
  });
};

const initCapMethodAction = (state, action) => {
  return updateObject(state, {
    loadCap: true,
    loadCapBtn: true,
    error: false
  });
};

const fetchApiFailed = (state, action) => {
  if (action.data) {
    let message = undefined;
    if (action.data.detail) message = action.data.detail.message;
    else if (action.data.message) message = action.data.message;

    return updateObject(state, {
      error: true,
      loading: false,
      loadCap: false,
      loadAddress: false,
      loadCapBtn: false,
      message
    });
  }
  return updateObject(state, { error: true, loading: false, loadCap: false, loadCapBtn: false });
};

const fetchApiAlert = (state, action) => {
  return updateObject(state, {
    alert: true,
    loading: false,
    loadCap: false,
    loadCapBtn: false,
    message: action.data
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_ACCOUNT_DATA:
      return setAccountData(state, action);
    case actionTypes.SET_INIT_RENEG_ERROR:
      return setInitialRenegotiationError(state, action);
    case actionTypes.SET_ACCOUNT_PROMOTIONS:
      return setAvailablePromotions(state, action);
    case actionTypes.SET_RENEGOTIATION_PRICING_HISTORY:
      return setRenegotiationPricingHistory(state, action);
    case actionTypes.SET_ACCOUNT_CHAINING_PRICING:
      return setAccountChainingPricing(state, action);
    case actionTypes.UPDATE_ACCOUNT_CHAIN_STONECODE:
      return updateChainStonecode(state, action);
    case actionTypes.UPDATE_ACCOUNT_PRICING:
      return updateAccountPricing(state, action);
    case actionTypes.UPDATE_ACCOUNT:
      return updateAccount(state, action);
    case actionTypes.INIT_ACCOUNT_PRICING_ACTION:
      return initPricingAction(state, action);
    case actionTypes.RENEG_INIT_CAP_METHOD_ACTION:
      return initCapMethodAction(state, action);
    case actionTypes.SET_RENEG_CAP_METHODS:
      return setRenegCapMethods(state, action);
    case actionTypes.FETCH_RENEG_API_FAILED:
      return fetchApiFailed(state, action);
    case actionTypes.FETCH_RENEG_API_ALERT:
      return fetchApiAlert(state, action);
    case actionTypes.SET_RENEGOTIATION_SUCCESS:
      return setRenegotiationSuccess(state, action);
    case actionTypes.DISMISS_RENEGOTIATION_SUCCESS:
      return dismissRenegotationSuccess(state, action);
    case actionTypes.UPDATE_ACCOUNT_ERROR_STATUS:
      return updateRenegotiationErrorStatus(state, action);
    case actionTypes.SET_RENEGOTIATION_CHAMPION_PRICE:
      return updateChampionPriceBundle(state, action);
    case actionTypes.SET_FAST_SIMULATION_RENEG:
      return setFastSimulationPricing(state, action);
    case actionTypes.UPDATE_ACCOUNT_PRICING_BUNDLE:
      return updateAccountPricingBundle(state, action);
    case actionTypes.SET_RENEGOTIATION_PARAMS:
      return setNegotiationParams(state, action);
    case actionTypes.SET_RENEGOTIATION_CONTACTS:
      return setContacts(state, action);
    case actionTypes.REFRESH_RENEGOTIATION_PARAMS:
      return refreshRenegotiationParams(state, action);
    case actionTypes.SET_RENEGOTIATION_ADDRESS:
      return setAddresses(state, action);
    case actionTypes.INIT_ADDRESS_LOADING:
      return initAddressLoading(state, action);
    case actionTypes.SET_HISTORIC_RENEGOTIATION:
      return {
        ...state,
        originalAccountId: state.accountId,
        historicRecordId: action.recordId,
        historicRecordDate: action.createDate
      };
    case actionTypes.UNSET_HISTORIC_RENEGOTIATION:
      return {
        ...state,
        accountId: state.originalAccountId,
        historicRecordId: undefined,
        historicRecordDate: undefined
      };
    case actionTypes.SET_RENEG_CONTRACT_PENDING:
      return setContractPending(state);
    case actionTypes.SET_RENEG_RICH_CONTRACT_PENDING:
      return setRichContractPending(state);
    case actionTypes.SET_ACCOUNT_CONTRACT:
      return setContractData(state, action);
    case actionTypes.SET_RENEG_RICH_CONTRACT:
      return setRichContract(state, action);
    case actionTypes.SET_RENEG_INFOPLEX:
      return setInfoplexData(state, action);
    default:
      return state;
  }
};

export default reducer;
