import { compose, combineReducers, createStore as r_createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

import negotiationReducer from './reducers/negotiation';
import pricingReducer from './reducers/pricing';
import affiliationReducer from './reducers/affiliation';
import renegotiationReducer from './reducers/renegotiation';
import abConfigReducer from './reducers/config';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({
  negotiation: negotiationReducer,
  pricing: pricingReducer,
  affiliation: affiliationReducer,
  renegotiation: renegotiationReducer,
  abConfig: abConfigReducer
});

export const createStore = (initialState = {}) =>
  r_createStore(rootReducer, initialState, composeEnhancers(applyMiddleware(thunk)));

export const store = createStore();
