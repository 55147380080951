import { AUTH_CALLBACK_URL, LOGIN_URL, NEGOTIATION_URL, APP_HOME } from '../constants/routes';

const RoutesController = ({
  pathname,
  sessionId,
  exited,
  setSessionId,
  cookieSessionId,
  history
}) => ({
  mount: async () => {
    const response = {};

    const trySetSessionIdByCookie = () => {
      setSessionId(cookieSessionId);
      return response;
    };

    const redirectToHome = () => {
      if ([AUTH_CALLBACK_URL, LOGIN_URL].includes(pathname)) {
        history.push(APP_HOME);
      }
      return response;
    };

    const authenticatedFlow = {
      trySetSessionIdByCookie,
      redirectToHome
    };

    if (exited) {
      history.push(APP_HOME);
      return response;
    }

    if (sessionId) {
      authenticatedFlow.redirectToHome();
      return response;
    }

    if (cookieSessionId) {
      authenticatedFlow.trySetSessionIdByCookie();
      return response;
    }

    if (pathname === AUTH_CALLBACK_URL) {
      return response;
    }
  }
});

export default RoutesController;
