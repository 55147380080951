import * as actionTypes from '../actions/actionTypes';
import * as NegotiationHelper from '../../utils/helpers/NegotiationHelper';
import { updateObject } from '../../shared/utility';

const initialState = {
  opportunity: undefined,
  configurations: false,
  pricing: undefined,
  message: undefined,
  infoplexData: {},
  pricingHistory: [],
  loadProposal: false,
  loadCapBtn: false,
  loadCap: false,
  isShareLoading: false,
  error: false,
  alert: false,
  salesforceSessionId: null,
  chainStonecode: null,
  tag: null,
  initError: false,
  loading: false,
  availablePromotions: ['0 - Sem Campanha']
};

const setOpportunityData = (state, action) => {
  if (action.opportunity.PricingObject.Xml_Pricing__c) {
    NegotiationHelper.handlePNLResult(
      action.opportunity,
      action.opportunity.PricingObject.Xml_Pricing__c
    );
  }
  return updateObject(state, {
    salesforceSessionId: action.salesforceSessionId,
    opportunity: action.opportunity,
    loadProposal: false,
    loadCap: false,
    loadCapBtn: false,
    initError: false,
    loading: false,
    tag: action.opportunity.Encadeamento__r ? 'Encadeado' : null,
    fastSimulateStatus: undefined
  });
};

const setContractPending = (state, action) => {
  return { ...state, contractPending: true };
};

const setContractData = (state, action) => {
  return { ...state, contract: action.contract, contractPending: false };
};

const setInfoplexData = (state, action) => {
  return updateObject(state, {
    infoplexData: action.infoplexData
  });
};

const setConfigurations = (state, action) => {
  return updateObject(state, {
    configurations: action.configurations
  });
};

const setAvailablePromotions = (state, action) => {
  return updateObject(state, {
    availablePromotions: action.availablePromotions
  });
};

const setCaptureMethods = (state, action) => {
  return updateObject(state, {
    ...state,
    opportunity: {
      ...state.opportunity,
      captureMethods: action.capMethod
    },
    loadProposal: false,
    loadCap: false,
    loadCapBtn: false,
    error: false
  });
};

const setNegotiationParams = (state, action) => {
  return updateObject(state, {
    ...state,
    salesforceSessionId: action.salesforceSessionId,
    opportunityId: action.opportunityId,
    opportunity: undefined
  });
};

const setChampionPrice = (state, action) => {
  const live = state.opportunity;
  const po = live.PricingObject;

  NegotiationHelper.handleFastSimulation(state, po);
  return updateObject(state, {
    ...state,
    opportunity: {
      ...state.opportunity,
      PricingObject: {
        ...po,
        ...action.pricing
      }
    },
    loadProposal: false
  });
};

const setChainingPricing = (state, action) => {
  const pricing = action.pricing;
  const live = state.opportunity;
  const po = live.PricingObject;

  NegotiationHelper.handleChainingProposal(state, pricing);
  return updateObject(state, {
    ...state,
    opportunity: {
      ...state.opportunity,
      PricingObject: {
        ...po
      }
    },
    loadProposal: false,
    tag: 'Encadeado'
  });
};

const setFastSimulationPricing = (state, action) => {
  return updateObject(state, {
    ...state,
    opportunity: {
      ...state.opportunity,
      PricingObject: {
        ...action.pricing
      }
    },
    loadProposal: false,
    fastSimulateStatus: action.status
  });
};

const setNegotiationPricingHistory = (state, action) => {
  return updateObject(state, {
    ...state,
    pricingHistory: action.data,
    loading: false,
    error: false
  });
};

const refreshNegotiationParams = (state, action) => {
  return updateObject(state, {
    ...state,
    opportunityId: undefined
  });
};

const updateChainStonecode = (state, action) => {
  return updateObject(state, {
    ...state,
    chainStonecode: action.stonecode
  });
};

const updatePricingObject = (state, action) => {
  const live = state.opportunity;
  const po = live.PricingObject;

  NegotiationHelper.handleFastSimulation(state, po);
  if (action.fieldName === 'Stone_Fast__c') {
    if (action.newValue === true) {
      return updateObject(state, {
        ...state,
        fastSimulateStatus: undefined,
        opportunity: {
          ...state.opportunity,
          PricingObject: {
            ...po,
            Antecipacao_Automatica__c: true,
            [action.fieldName]: action.newValue
          }
        }
      });
    }
  }

  if (action.expandPricingChanges) {
    if (action.fieldName === 'Credito_a_Vista_EloSub__c') {
      return {
        ...state,
        fastSimulateStatus: undefined,
        opportunity: {
          ...state.opportunity,
          PricingObject: {
            ...po,
            [action.fieldName]: action.newValue,
            Credito_Vista_Amex__c: action.newValue,
            credito_a_vista_hiper__c: action.newValue
          }
        }
      };
    }

    if (action.fieldName === 'Credito_2_a_6_EloSub__c') {
      return {
        ...state,
        fastSimulateStatus: undefined,
        opportunity: {
          ...state.opportunity,
          PricingObject: {
            ...po,
            [action.fieldName]: action.newValue,
            Credito_2_a_6_Amex__c: action.newValue,
            credito_2_a_6_hiper__c: action.newValue
          }
        }
      };
    }

    if (action.fieldName === 'Credito_7_a_12_EloSub__c') {
      return {
        ...state,
        fastSimulateStatus: undefined,
        opportunity: {
          ...state.opportunity,
          PricingObject: {
            ...po,
            [action.fieldName]: action.newValue,
            Credito_7_a_12_Amex__c: action.newValue,
            credito_7_a_12_hiper__c: action.newValue
          }
        }
      };
    }
  }

  return updateObject(state, {
    ...state,
    fastSimulateStatus: undefined,
    opportunity: {
      ...state.opportunity,
      PricingObject: {
        ...po,
        [action.fieldName]: action.newValue
      }
    }
  });
};

const updatePricingObjectBundle = (state, action) => {
  const live = state.opportunity;
  const po = live.PricingObject;

  NegotiationHelper.handleFastSimulation(state, po);
  return updateObject(state, {
    ...state,
    opportunity: {
      ...state.opportunity,
      PricingObject: {
        ...po,
        ...action.pricing
      }
    },
    loadProposal: false
  });
};

const updateOpportunity = (state, action) => {
  return updateObject(state, {
    ...state,
    opportunity: {
      ...state.opportunity,
      [action.fieldName]: action.newValue
    },
    loadProposal: false,
    loadCap: false,
    loadCapBtn: false,
    error: false
  });
};

const initCapMethodAction = (state, action) => {
  return updateObject(state, {
    loadCap: true,
    loadCapBtn: true,
    error: false
  });
};

const initPricingAction = (state, action) => {
  return updateObject(state, {
    loadProposal: true,
    loading: true
  });
};

const initFastSimulation = (state, action) => {
  return updateObject(state, {
    loadProposal: true
  });
};

const fetchApiFailed = (state, action) => {
  if (action.data) {
    return updateObject(state, { error: true, loadProposal: false, message: action.data.message });
  }
  return updateObject(state, { error: true, loadProposal: false });
};

const setInitNegError = (state, action) => {
  return updateObject(state, {
    initError: true,
    loading: false
  });
};

const updateErrorStatus = (state, action) => {
  return updateObject(state, { error: false, alert: false, message: undefined });
};

const fetchCapMethodFailed = (state, action) => {
  return updateObject(state, {
    error: true,
    loadCap: false,
    loadCapBtn: false,
    message: action.message
  });
};

const fetchApiAlert = (state, action) => {
  return updateObject(state, { alert: true, loadProposal: false, message: action.data });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_OPPORTUNITY:
      return setOpportunityData(state, action);
    case actionTypes.SET_NEG_CONTRACT_PENDING:
      return setContractPending(state, action);
    case actionTypes.SET_NEG_CONTRACT:
      return setContractData(state, action);
    case actionTypes.SET_PROMOTIONS:
      return setAvailablePromotions(state, action);
    case actionTypes.SET_INFOPLEX:
      return setInfoplexData(state, action);
    case actionTypes.SET_CONFIGURATIONS:
      return setConfigurations(state, action);
    case actionTypes.SET_INIT_NEG_ERROR:
      return setInitNegError(state, action);
    case actionTypes.SET_NEGOTIATION_PARAMS:
      return setNegotiationParams(state, action);
    case actionTypes.REFRESH_NEGOTIATION_PARAMS:
      return refreshNegotiationParams(state, action);
    case actionTypes.SET_CAP_METHODS:
      return setCaptureMethods(state, action);
    case actionTypes.SET_CHAMPION_PRICE:
      return setChampionPrice(state, action);
    case actionTypes.SET_FAST_SIMULATION_PRICING:
      return setFastSimulationPricing(state, action);
    case actionTypes.SET_CHAINING_PRICING:
      return setChainingPricing(state, action);
    case actionTypes.SET_NEGOTIATION_PRICING_HISTORY:
      return setNegotiationPricingHistory(state, action);
    case actionTypes.UPDATE_CHAIN_STONECODE:
      return updateChainStonecode(state, action);
    case actionTypes.UPDATE_PRICING:
      return updatePricingObject(state, action);
    case actionTypes.UPDATE_PRICING_BUNDLE:
      return updatePricingObjectBundle(state, action);
    case actionTypes.UPDATE_OPPORTUNITY:
      return updateOpportunity(state, action);
    case actionTypes.INIT_CAP_METHOD_ACTION:
      return initCapMethodAction(state, action);
    case actionTypes.INIT_FAST_SIMULATION:
      return initFastSimulation(state, action);
    case actionTypes.INIT_PRICING_ACTION:
      return initPricingAction(state, action);
    case actionTypes.FETCH_NEG_API_FAILED:
      return fetchApiFailed(state, action);
    case actionTypes.FETCH_NEG_API_ALERT:
      return fetchApiAlert(state, action);
    case actionTypes.UPDATE_ERROR_STATUS:
      return updateErrorStatus(state, action);
    case actionTypes.FETCH_CAP_METHOD_FAILED:
      return fetchCapMethodFailed(state, action);
    case actionTypes.NEG_SHARE_LOADING:
      return { ...state, isShareLoading: action.payload };
    default:
      return state;
  }
};

export default reducer;
