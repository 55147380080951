import { GET_APP_CONFIG, SET_APP_CONFIG } from '../actions/actionTypes';

const initialState = {
  values: null,
  loading: false
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_APP_CONFIG:
      return {
        ...state,
        loading: true
      };
    case SET_APP_CONFIG:
      return {
        ...state,
        values: { ...state.values, ...action.payload },
        loading: false
      };
    default:
      return state;
  }
}
